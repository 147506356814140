<template>
  <div class="main">
    <CreateAdminUser ref="CreateAdminUser" />
    <EditUserModal ref="EditUserModal" />
    <b-row>
      <b-col md="12">
        <div class="form-group">
          <label>Status</label>
          <b-form-select @input="getUsers" v-model="filters.city_manager_approved" :options="approvedStatuses" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="12" v-if="users.length === 0"><b-alert show variant="primary">Nu exista utilizatori</b-alert></b-col>
      <b-col md="4" v-for="(user, index) in users" :key="index">
        <div class="box_order_form">
          <div class="head text-center">
            <h3>
              <b-link
                @click="editUser(user.id)"
            >
              <i class="mdi mdi-pencil"
                 style="font-size: 24px; color: #A1A7AF;">
              </i>
            </b-link>
              <b-link
                  class="ml-2 mr-2"
                  @click="impersonateUser(user.id)"
              >
                <i class="mdi mdi-account"
                   style="font-size: 24px; color: #A1A7AF;">
                </i>
              </b-link>{{ user.first_name }} {{ user.last_name }}</h3>
          </div>
          <div class="main">
            Email: {{ user.email }} <br>
            Telefon: {{ user.phone }} <br>
            Autovehicul: {{ user.vehicle_type }} <br>
          </div>
          <div class="footer text-center">
            <a :href="'tel:' + user.phone" class="btn btn-primary">SUNA</a>
          </div>
          <div class="footer text-center">
            <b-button @click="changeStatus(user, true)" v-if="user.city_manager_approved === null || !user.city_manager_approved" class="m-2" variant="success">APROBA</b-button>
            <b-button @click="changeStatus(user, false)" v-if="user.city_manager_approved === null" class="m-2" variant="danger">RESPINGE</b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="users.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CityManagerUsers from '@/components/cityManagerEndpoints/users'
import CreateAdminUser from '@/views/CityManager/Users/CreateUserModal'
import EditUserModal from '@/views/CityManager/Users/EditUserModal'
import { bus } from "../../../main";
export default {
  data() {
    return {
      error: false,
      users: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10,
        search: ''
      },
      filters: {
        city_manager_approved: 'pending'
      },
      userTypes: {
        'restaurant-manager': 'Manager Restaurant',
        'driver': 'Livrator'
      }
    }
  },
  computed: {
    approvedStatuses() {
      return [
          /*
        {
          value: '',
          text: 'TOTI',
        },

           */
        {
          value: 'pending',
          text: 'In Asteptare',
        },
          /*
        {
          value: 'approved',
          text: 'Acceptati',
        },

           */
        {
          value: 'declined',
          text: 'Respinsi',
        }
      ]
    },
  },
  components: {
    CreateAdminUser,
    EditUserModal
  },
  methods: {
    changeStatus(user, type) {
      user.city_manager_approved = type
      user.is_active = true
      user.address = ' '
      CityManagerUsers.update(user.id, user).then(response => {
        this.getUsers()
      })
    },
    async impersonateUser(userId) {
      let loginResponse = await CityManagerUsers.impersonate(userId)
      if (loginResponse.success) {
        localStorage.setItem('token', loginResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user_data))
        bus.$emit('loggedIn', loginResponse.data.user_data)
        this.$nextTick(() => {
          if (loginResponse.data.user_data.role === 'restaurant-manager') {
            this.$router.push({ name: 'restaurant-manager.dashboard' })
          } else if (loginResponse.data.user_data.role === 'city-manager') {
            this.$router.push({name: 'city-manager.dashboard'})
          } else if (loginResponse.data.user_data.role === 'driver') {
            this.$router.push({ name: 'driver.dashboard' })
          } else if (loginResponse.data.user_data.role === 'customer') {
            this.$router.push({ name: 'customer.account' })
          }
        })
      }
    },
    showModal()
    {
      this.$root.$refs.CityManagerCreateUserModal.showModal = true;
    },
    editUser(id)
    {
      bus.$emit('showAdminUserEditModal', id)
    },
    getUsers()
    {
      CityManagerUsers.getAll(this.paging, this.filters).then(response => {
        this.users = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page)
    {
      this.paging.page = page
      this.getUsers()
    }
    },
  mounted() {
    bus.$on('userCreated', () => {
      this.getUsers()
    })
    bus.$on('userUpdated', () => {
      this.getUsers()
    })
    this.getUsers()
  }
}
</script>