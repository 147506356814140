<template>
  <main class="bg_gray" style="padding-top: 150px;">
    <b-container>
      <b-row>
        <b-col md="3">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>MENIU ADMINISTRARE</h3>
            </div>
            <div class="impersonated" v-if="impersonatedUsers">
              <b-row>
                <b-col md="12" class="p-1" v-for="(user, index) in impersonatedUsers" :key="index">
                  <b-button @click="backToUser(user)" block class="w-100" variant="primary"><i class="mdi mdi-exit-run"></i> {{ user.user_data.email }}</b-button>
                </b-col>
              </b-row>
            </div>
            <!-- /head -->
            <div class="main">
              <div>
                <b-nav pills vertical class="text-center">
                  <b-nav-item :active="$route.name === 'city-manager.dashboard'" :to="{ name: 'city-manager.dashboard' }">Dashboard</b-nav-item>
                  <b-nav-item :active="$route.name === 'city-manager.restaurants'" :to="{ name: 'city-manager.restaurants' }">Restaurante</b-nav-item>
                  <b-nav-item :active="$route.name === 'city-manager.orders'" :to="{ name: 'city-manager.orders' }">Comenzi</b-nav-item>
                  <b-nav-item :active="$route.name === 'city-manager.users'" :to="{ name: 'city-manager.users' }">Utilizatori</b-nav-item>
                  <b-nav-item :active="$route.name === 'city-manager.users.pending'" :to="{ name: 'city-manager.users.pending' }">Utilizatori in asteptare</b-nav-item>
                  <b-nav-item :active="$route.name === 'city-manager.invoices'" :to="{ name: 'city-manager.invoices' }">Facturi emise</b-nav-item>
                  <b-nav-item :active="$route.name === 'city-manager.settings'" :to="{ name: 'city-manager.settings' }">Setari</b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.dashboard'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>DASHBOARD</h3>
            </div>
            <!-- /head -->
            <Statistics />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.restaurants'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>RESTAURANTE</h3>
            </div>
            <RestaurantsTable />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.orders'">
          <div class="box_order_form" ref="OrdersTable">
            <b-row>
              <div class="head text-center">
                <h3>COMENZI</h3>
              </div>
              <div style="position: absolute; left: 15px; top: 5px;">
                <b-link
                    @click="ordersFullScreen"
                >
                  <i class="mdi mdi-arrow-expand-all"
                     style="font-size: 24px; color: white;">
                  </i>
                </b-link>
              </div>
            </b-row>
            <OrdersTable />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.users'">
          <div class="box_order_form" ref="OrdersTable">
            <b-row>
              <div class="head text-center">
                <h3>UTILIZATORI</h3>
              </div>
            </b-row>
            <UsersTable />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.users.pending'">
          <div class="box_order_form" ref="OrdersTable">
            <b-row>
              <div class="head text-center">
                <h3>UTILIZATORI IN ASTEPTARE</h3>
              </div>
            </b-row>
            <UsersTablePending />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.invoices'">
          <div class="box_order_form" ref="OrdersTable">
            <b-row>
              <div class="head text-center">
                <h3>FACTURI EMISE</h3>
              </div>
            </b-row>
            <Invoices />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'city-manager.settings'">
          <div class="box_order_form" ref="Settings">
            <b-row>
              <div class="head text-center">
                <h3>SETARI</h3>
              </div>
            </b-row>
            <Settings />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import Statistics from '@/views/CityManager/Statistics'
import OrdersTable from '@/views/CityManager/Orders/Table'
import RestaurantsTable from '@/views/CityManager/Restaurants/Table'
import UsersTable from '@/views/CityManager/Users/Table'
import UsersTablePending from '@/views/CityManager/Users/TablePending'
import Settings from '@/views/CityManager/Settings/Index'
import Invoices from '@/views/CityManager/Invoices/Table'
import {bus} from "../../main";

export default {
  data() {
    return {
      currentUserToken: localStorage.getItem('token')
    }
  },
  components: {
    Statistics,
    OrdersTable,
    RestaurantsTable,
    UsersTable,
    UsersTablePending,
    Settings,
    Invoices
  },
  computed: {
    impersonatedUsers() {
      let impersonatedUsers = localStorage.getItem('impersonated');
      if (impersonatedUsers) {
        return JSON.parse(impersonatedUsers)
      }
      return false;
    },
  },
  methods: {
    ordersFullScreen() {
      if(!document.fullscreenElement) {
        this.$refs.OrdersTable.requestFullscreen()
      } else {
        document.exitFullscreen();
      }
    },
    backToUser(user) {
      localStorage.setItem('token', user.token)
      localStorage.setItem('userInfo', JSON.stringify(user.user_data))
      if (user.user_data.role === 'restaurant-manager') {
        this.$router.push({ name: 'restaurant-manager.dashboard' })
      } else if (user.user_data.role === 'city-manager') {
        this.$router.push({name: 'city-manager.dashboard'})
      } else if (user.user_data.role === 'driver') {
        this.$router.push({ name: 'driver.dashboard' })
      } else if (user.user_data.role === 'customer') {
        this.$router.push({ name: 'customer.account' })
      } else if (user.user_data.role === 'administrator') {
        this.$router.push({ name: 'admin.dashboard' })
        localStorage.removeItem('impersonated')
      }
      bus.$emit('loggedIn', user.user_data);
    }
  }
}
</script>