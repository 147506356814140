<template>
  <div class="main">
    <b-modal
        id="invoiceOverMail"
        hide-header
        hide-footer
        v-model="invoiceOverMailModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Trimite factura pe E-Mail</h3>
            <span>Factura: {{ selectedInvoice.invoice_prefix }}-{{ selectedInvoice.invoice_nr }}</span> <br>
            <span>Valoare: {{ toCurrency(selectedInvoice.invoice_amount) }}</span> <br>
            <span>Client: {{ selectedInvoice.customer_name }}</span>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Email destinatar</label>
              <b-input type="text" v-model="invoiceMail.email" placeholder="Email (obligatoriu)"></b-input>
            </div>
            <div class="form-group">
              <label>Nume destinatar</label>
              <b-input type="text" v-model="invoiceMail.name" placeholder="Nume (obligatoriu)"></b-input>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" class="p-1">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6" class="p-1">
            <b-button v-if="!isBusy" variant="primary" @click="sendMail" style="width: 100%;">Trimite factura</b-button>
            <b-button v-else variant="secondary" style="width: 100%;">Trimite factura...</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-row>
      <b-col md="12">
        <b-form-input @keyup="getInvoices" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="min-width: 30px;"></b-th>
              <b-th>Data</b-th>
              <b-th>Nr. Factura</b-th>
              <b-th>Nr. Comanda</b-th>
              <b-th>Client</b-th>
              <b-th>Total</b-th>
            </b-tr>
          </b-thead>
            <b-tr v-for="invoice of invoices" :key="invoice.id">
              <b-td>
                <b-link
                    @click="downloadPDF(invoice)"
                >
                  <i class="mdi mdi-file-pdf"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link
                    @click="showMailModal(invoice)"
                >
                  <i class="mdi mdi-at"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>
                {{ formatDate(invoice.created_at) }}
              </b-td>
              <b-td>
                {{ invoice.invoice_prefix }}-{{ invoice.invoice_nr }}
              </b-td>
              <b-td>
                {{ invoice.order_id }}
              </b-td>
              <b-td>
                {{ invoice.customer_name }}
              </b-td>
              <b-td>
                {{ toCurrency(invoice.invoice_amount) }}
              </b-td>
            </b-tr>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="invoices.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Invoices from '@/components/cityManagerEndpoints/invoices'

export default {
  data() {
    return {
      isBusy: false,
      error: false,
      invoices: [],
      paging: {
        page: 1,
        per_page: 100,
        total: 10,
        search: ''
      },
      invoiceOverMailModal: false,
      invoiceMail: {
        email: '',
        name: '',
      },
      selectedInvoice: {}
    }
  },
  methods: {
    cancel() {
      this.invoiceMail =  {
        email: '',
        name: '',
      }
      this.selectedInvoice = {}
      this.invoiceOverMailModal = false;
    },
    showMailModal(invoice) {
      this.selectedInvoice = invoice
      this.invoiceOverMailModal = true;
    },
    sendMail() {
      this.isBusy = true
      Invoices.sendMail(this.selectedInvoice.id, this.invoiceMail).then(() => {
        this.isBusy = false
        this.$toastr.s('Factura a fost trimisa pe E-mail');
        this.cancel()
      }).catch(() => {
        this.$toastr.s('Factura nu a putut fi trimisa. Verificati campurile completate');
        this.isBusy = false
      })
    },
    getInvoices() {
      Invoices.getAll(this.paging).then(response => {
        this.invoices = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getInvoices()
    },
    downloadPDF(invoice) {
      Invoices.download(invoice.uuid);
    },
    showPDF(invoiceId) {
      Invoices.download(invoiceId).then(response => {
        var binaryString = window.atob(response.data.data)
        var binaryLen = binaryString.length
        var bytes = new Uint8Array(binaryLen)
        for(var i = 0; i < binaryLen; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        var blob = new Blob([bytes], { type: 'application/pdf' })
        window.open(URL.createObjectURL(blob), "_blank")
      });
    }
  },
  mounted() {
    this.getInvoices()
  }
}
</script>
