<template>
  <div class="main">
    <EditRestaurant ref="EditCityManagerRestaurant" />
    <CreateRestaurant ref="CreateCityManagerRestaurant" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showCreateModal">
          Adauga un restaurant
        </b-button>
      </b-col>
      <b-col md="12">
        <b-form-input @keyup="getRestaurants" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="min-width: 110px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>Nume</b-th>
              <b-th>Adresa</b-th>
              <b-th>Cod Postal</b-th>
              <b-th>Oras</b-th>
            </b-tr>
          </b-thead>
          <draggable
              v-model="restaurants"
              tag="tbody"
              handle=".handle"
              ghost-class="ghost"
              @end="changePositions"
          >
            <b-tr v-for="restaurant of restaurants" :key="restaurant.id">
              <b-td>
                <b-link class="handle mr-1" style="cursor: move">
                  <i class="mdi mdi-sort"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link class="mr-1"
                    @click="editRestaurant(restaurant.id)"
                >
                  <i class="mdi mdi-pencil"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link
                    @click="impersonateUser(restaurant.owner_user_id)"
                >
                  <i class="mdi mdi-account"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>{{restaurant.id}}</b-td>
              <b-td>{{restaurant.name}}</b-td>
              <b-td>{{restaurant.street}}</b-td>
              <b-td>{{restaurant.zip}}</b-td>
              <b-td>{{restaurant.city.name}}</b-td>
            </b-tr>
          </draggable>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="restaurants.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CityManagerUsers from '@/components/cityManagerEndpoints/users'
import Restaurants from '@/components/cityManagerEndpoints/restaurants'
import EditRestaurant from '@/views/CityManager/Restaurants/EditRestaurantModal'
import CreateRestaurant from '@/views/CityManager/Restaurants/CreateRestaurantModal'
import {bus} from "../../../main";
import draggable from "vuedraggable";

export default {
  data() {
    return {
      error: false,
      restaurants: [],
      paging: {
        page: 1,
        per_page: 100,
        total: 10,
        search: ''
      }
    }
  },
  components: {
    draggable,
    EditRestaurant,
    CreateRestaurant
  },
  methods: {
    editRestaurant(id) {
      bus.$emit('showCityManagerRestaurantEditModal', id)
    },
    changePositions(data) {
      this.restaurants.forEach((restaurant, index) => {
        this.restaurants[index].order_index = index
        this.restaurants[index].city_id = this.restaurants[index].city.id
        Restaurants.update(restaurant.id, restaurant).then(() => {

        })
      })
    },
    async impersonateUser(userId) {
      let loginResponse = await CityManagerUsers.impersonate(userId)
      if (loginResponse.success) {
        localStorage.setItem('token', loginResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user_data))
        bus.$emit('loggedIn', loginResponse.data.user_data)
        this.$nextTick(() => {
          if (loginResponse.data.user_data.role === 'restaurant-manager') {
            this.$router.push({ name: 'restaurant-manager.dashboard' })
          } else if (loginResponse.data.user_data.role === 'city-manager') {
            this.$router.push({name: 'city-manager.dashboard'})
          } else if (loginResponse.data.user_data.role === 'driver') {
            this.$router.push({ name: 'driver.dashboard' })
          } else if (loginResponse.data.user_data.role === 'customer') {
            this.$router.push({ name: 'customer.account' })
          }
        })
      }
    },
    getRestaurants() {
      Restaurants.getAll(this.paging).then(response => {
        this.restaurants = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getRestaurants()
    },
    showCreateModal() {
      this.$root.$refs.CityManagerCreateRestaurantModal.showModal = true;
    },
  },
  mounted() {
    bus.$on('restaurantCreated', () => {
      this.getRestaurants()
    })
    bus.$on('restaurantUpdated', () => {
      this.getRestaurants()
    })
    this.getRestaurants()
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #1bc5bd;
}
</style>