<template>
  <div class="main">
    <CreateAdminUser ref="CreateAdminUser" />
    <EditUserModal ref="EditUserModal" />
    <b-row>
      <b-col md="12" class="m-2">
        <b-button variant="primary" @click="showModal">
          Adaugare utilizator
        </b-button>
      </b-col>
      <b-col md="12">
        <b-form-input @keyup="getUsers" placeholder="Cauta..." type="text" v-model="paging.search"></b-form-input>
      </b-col>
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th style="min-width: 90px;"></b-th>
              <b-th>#ID</b-th>
              <b-th>Email</b-th>
              <b-th>Nume</b-th>
              <b-th>Prenume</b-th>
              <b-th style="min-width: 250px;">Adresa</b-th>
              <b-th style="min-width: 150px;">Cod Postal</b-th>
              <b-th>Oras</b-th>
              <b-th>Telefon</b-th>
              <b-th style="min-width: 250px;">TIP</b-th>
              <b-th style="min-width: 50px;">STATUS</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="user of users" :key="user.id">
              <b-td>
                <b-link
                    @click="editUser(user.id)"
                >
                  <i class="mdi mdi-pencil"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
                <b-link
                    class="ml-2"
                    @click="impersonateUser(user.id)"
                >
                  <i class="mdi mdi-account"
                     style="font-size: 24px; color: #A1A7AF;">
                  </i>
                </b-link>
              </b-td>
              <b-td>{{user.id}}</b-td>
              <b-td>{{user.email}}</b-td>
              <b-td>{{user.last_name}}</b-td>
              <b-td>{{user.first_name}}</b-td>
              <b-td>{{user.address}}</b-td>
              <b-td>{{user.zip}}</b-td>
              <b-td>{{user.city}}</b-td>
              <b-td>{{user.phone}}</b-td>
              <b-td>
                <b-row>
                  <b-col md="12">
                    {{userTypes[user.role]}}
                  </b-col>
                  <b-col md="12" v-if="user.role === 'city-manager' && user.manager_city">

                  </b-col>
                  <b-col v-else-if="user.role === 'restaurant-manager'">
                    <span v-if="user.restaurant_data">
                      ({{ user.restaurant_data.name }} - {{ user.restaurant_data.city.name }})
                    </span>
                  </b-col>
                </b-row>
              </b-td>
              <b-td>
                <b-alert show class="p-2 text-center" variant="success" v-if="user.is_active">ACTIV</b-alert>
                <b-alert show class="p-2 text-center" variant="danger" v-else>INACTIV</b-alert>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="users.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CityManagerUsers from '@/components/cityManagerEndpoints/users'
import CreateAdminUser from '@/views/CityManager/Users/CreateUserModal'
import EditUserModal from '@/views/CityManager/Users/EditUserModal'
import { bus } from "../../../main";
export default {
  data() {
    return {
      error: false,
      users: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10,
        search: ''
      },
      userTypes: {
        'restaurant-manager': 'Manager Restaurant',
        'driver': 'Livrator'
      }
    }
  },
  components: {
    CreateAdminUser,
    EditUserModal
  },
  methods: {
    async impersonateUser(userId) {
      let loginResponse = await CityManagerUsers.impersonate(userId)
      if (loginResponse.success) {
        localStorage.setItem('token', loginResponse.data.token)
        localStorage.setItem('userInfo', JSON.stringify(loginResponse.data.user_data))
        bus.$emit('loggedIn', loginResponse.data.user_data)
        this.$nextTick(() => {
          if (loginResponse.data.user_data.role === 'restaurant-manager') {
            this.$router.push({ name: 'restaurant-manager.dashboard' })
          } else if (loginResponse.data.user_data.role === 'city-manager') {
            this.$router.push({name: 'city-manager.dashboard'})
          } else if (loginResponse.data.user_data.role === 'driver') {
            this.$router.push({ name: 'driver.dashboard' })
          } else if (loginResponse.data.user_data.role === 'customer') {
            this.$router.push({ name: 'customer.account' })
          }
        })
      }
    },
    showModal()
    {
      this.$root.$refs.CityManagerCreateUserModal.showModal = true;
    },
    editUser(id)
    {
      bus.$emit('showAdminUserEditModal', id)
    },
    getUsers()
    {
      CityManagerUsers.getAll(this.paging).then(response => {
        this.users = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page)
    {
      this.paging.page = page
      this.getUsers()
    }
    },
  mounted() {
    bus.$on('userCreated', () => {
      this.getUsers()
    })
    bus.$on('userUpdated', () => {
      this.getUsers()
    })
    this.getUsers()
  }
}
</script>