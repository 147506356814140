<template>
  <div class="main">
    <b-row>
      <b-col md="6">
        <div class="box_order_form">
          <div class="head text-center">
            <h3><i class="mdi mdi-account"></i> DATE PERSONALE</h3>
          </div>
          <div class="main">
            <b-row class="p-2 border-bottom">
              <div class="form-group">
                <label>Nume</label>
                <b-input type="text" v-model="user.last_name" placeholder="Nume"></b-input>
              </div>
              <div class="form-group">
                <label>Prenume</label>
                <b-input type="text" v-model="user.first_name" placeholder="Prenume"></b-input>
              </div>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="box_order_form">
          <div class="head text-center">
            <h3><i class="mdi mdi-key"></i> CHEI AUTORIZARE PLATI STRIPE</h3>
          </div>
          <div class="main">
            <b-row class="p-2 border-bottom">
              <div class="form-group">
                <label>Cheia publica</label>
                <b-input type="text" v-model="user.stripe_public_key" placeholder="Cheia publica"></b-input>
              </div>
              <div class="form-group">
                <label>Cheia privata</label>
                <b-input type="text" v-model="user.stripe_private_key" placeholder="Cheia privata"></b-input>
              </div>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div class="box_order_form">
          <div class="head text-center">
            <h3><i class="mdi mdi-file-document"></i> SETARI FACTURARE</h3>
          </div>
          <div class="main">
            <b-row class="p-2 border-bottom">
              <div class="form-group">
                <label>Nume firma</label>
                <b-input type="text" v-model="user.company_data.name" placeholder="Nume Firma"></b-input>
              </div>
              <div class="form-group">
                <label>Adresa (strada, nr)</label>
                <b-input type="text" v-model="user.company_data.street" placeholder="Adresa"></b-input>
              </div>
              <div class="form-group">
                <label>Oras (Localitate)</label>
                <b-input type="text" v-model="user.company_data.city" placeholder="Oras"></b-input>
              </div>
              <div class="form-group">
                <label>Nr. Reg. Com</label>
                <b-input type="text" v-model="user.company_data.register_nr" placeholder="Nr. Reg. Com."></b-input>
              </div>
              <div class="form-group">
                <label>Cod Fiscal (C.U.I.) </label>
                <b-input type="text" v-model="user.company_data.vat_nr" placeholder="CUI"></b-input>
              </div>
              <div class="form-group">
                <label>Capital social (RON)</label>
                <b-input type="text" v-model="user.company_data.capital" placeholder="Capital social"></b-input>
              </div>
              <div class="form-group">
                <label>Serie Facturi</label>
                <b-input type="text" v-model="user.company_data.invoice_prefix" placeholder="Serie facturi"></b-input>
              </div>
              <div class="form-group">
                <label class="container_check">Generare automata facturi
                  <input v-model="user.company_data.auto_create_invoice" type="checkbox">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group" v-if="user.company_data.auto_create_invoice">
                <label class="container_check">Trimite factura automat prin E-Mail la client
                  <input v-model="user.company_data.send_customer_mail" type="checkbox">
                  <span class="checkmark"></span>
                </label>
              </div>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="12">
        <b-button @click="saveUser" class="p-2 m-2" style="width: 100%;" variant="primary">ACTUALIZEAZA DATELE</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Auth from '@/components/auth'

export default {
  data() {
    return {
      error: false,
      user: {
        first_name: '',
        last_name: '',
        stripe_public_key: '',
        stripe_private_key: '',
        company_data: {
          name: '',
          street: '',
          city: '',
          register_nr: '',
          vat_nr: '',
          capital: '',
          invoice_prefix: '',
          send_customer_mail: false,
          auto_create_invoice: false
        }
      }
    }
  },
  components: {

  },
  methods: {
    getData() {
      const userInfo = Auth.userInfo();
      if (userInfo.company_data === null) {
        userInfo.company_data = {
          name: '',
          street: '',
          city: '',
          register_nr: '',
          vat_nr: '',
          capital: '',
          invoice_prefix: '',
          send_customer_mail: false,
          auto_create_invoice: false
        }
      }
      this.user = userInfo;
    },
    saveUser() {
      Auth.update(this.user).then(response => {
        this.$swal('Date actualizate', 'Datele au fost actualizat cu success', 'success')
      })
    }
  },
  computed: {
  },
  mounted() {
    this.getData()
  }
}
</script>

<style>
ul.dropdown-menu{
  z-index: 100;
  position: absolute;
}
</style>