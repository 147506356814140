<template>
  <div>
    <b-modal
        id="create-user"
        hide-header
        hide-footer
        v-model="showModal"
        no-close-on-backdrop
        no-close-on-esc
    >
      <div>
        <b-row>
          <b-col md="12" class="text-center p-2">
            <h3>Adaugare utilizator</h3>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Email (obligatoriu)</label>
              <b-input inputmode="email" type="email" v-model="user.email" placeholder="Email"></b-input>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nume</label>
                  <b-input type="text" v-model="user.last_name" placeholder="Nume (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Prenume</label>
                  <b-input type="text" v-model="user.first_name" placeholder="Prenume (obligatoriu)"></b-input>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Adresa</label>
              <b-input type="text" v-model="user.address" placeholder="Strada nr, bloc, ap, etc (obligatoriu)"></b-input>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Oras</label>
                  <b-input type="text" v-model="user.city" placeholder="Oras (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cod Postal</label>
                  <b-input type="text" v-model="user.zip" placeholder="Nu este obligatoriu"></b-input>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Telefon</label>
              <b-input inputmode="tel" type="tel" maxlength="10" v-model="user.phone" placeholder="Telefon (obligatoriu)"></b-input>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Parola</label>
                  <b-input type="password" v-model="user.password" placeholder="Parola (obligatoriu)"></b-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Confirmare Parola</label>
                  <b-input type="password" v-model="user.c_password" placeholder="Confirmare Parola (obligatoriu)"></b-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tip Utilizator</label>
                  <b-form-select v-model="user.role" :options="roles" size="sm" class="form-control"></b-form-select>
                </div>
                  </div><div class="col-md-6">
                <div class="form-group">
                  <label>Status</label>
                  <b-form-select v-model="user.is_active" :options="statuses" size="sm" class="form-control"></b-form-select>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="user.role === 'city-manager'">
              <label>Procent comision managere oras</label>
              <b-input type="text" v-model="user.city_manager_platform_fee" placeholder="Procent comision"></b-input>
            </div>
            
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-button variant="secondary" @click="cancel" style="width: 100%;">Anuleaza</b-button>
          </b-col>
          <b-col md="6">
            <b-button variant="primary" @click="save" style="width: 100%;">Salveaza</b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Users from '@/components/cityManagerEndpoints/users'
import { bus } from "../../../main";

export default {
  data() {
    return {
      showModal: false,
      user: {
        email: '',
        password: '',
        c_password: '',
        first_name: '',
        last_name: '',
        role: 'restaurant-manager',
        address: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        city_manager_platform_fee: 0,
        is_active: true,
      },
      roles: [
        { value: 'restaurant-manager', text: 'Restaurant Manager' },
        { value: 'driver', text: 'Livrator' }
      ],
      statuses: [
        { value: true, text: 'Activ' },
        { value: false, text: 'Inactiv' },
      ],
    }
  },
  components: {

  },
  created() {
    this.$root.$refs.CityManagerCreateUserModal = this;
  },
  methods: {
    cancel() {
      this.showModal = false
      this.user = {
        email: '',
        password: '',
        c_password: '',
        first_name: '',
        last_name: '',
        role: 'restaurant-manager',
        address: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        city_manager_platform_fee: 0,
        is_active: true,
      }
    },
    save() {
      Users.store(this.user).then(response => {
        bus.$emit('userCreated', response.data.data)
        this.cancel()
      })
    }
  }
}
</script>
