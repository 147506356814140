import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Invoices {
  get (id) {
    return axios.get(url + '/city-manager/invoices/' + id)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/city-manager/invoices' + options)
  }

  store (data) {
    return axios.post(url + '/city-manager/invoices', data)
  }

  update (id, data) {
    return axios.put(url + '/city-manager/invoices/' + id, data)
  }

  download (uuid) {
    window.open(url + '/public/storage/invoice/' + uuid, '_blank');
  }

  sendMail (id, data) {
    return axios.post(url + '/city-manager/invoices/mail/' + id, data)
  }

}
export default new Invoices()
